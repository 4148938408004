<template>
  <v-container>
    <div class="back-plot">
      <!-- BOC:[error] -->
      <ApiErrorDialog v-if="api.isError" :api="api" />
      <!-- EOC -->
      <!-- BOC -->
      <v-card class="transparent" elevation="0">
        <v-card-title class="text-h5 grey lighten-5 rounded-lg py-2">
          <v-row>
            <v-col cols="9" class="d-flex rounded align-center">
              {{ auth.User.name }}
            </v-col>
            <v-col cols="3" class="d-flex align-center justify-end">
              <CloseBtn size="40" :callback="exit" />
            </v-col>
          </v-row>
        </v-card-title>
      </v-card>
      <!-- EOC -->
      <v-card
        v-if="body"
        class="px-2 pb-6"
        :class="
          settings.graphics && settings.graphics.mode == 'low'
            ? 'low_plot'
            : 'plot'
        "
      >
        <v-card-title>{{
          $t("view.PageEntranceStudentAvatarSelect.title")
        }}</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="5">
              <div
                class="d-flex flex-column justify-center align-center text-center pa-6 mt-10 mb-12"
              >
                <v-img
                  class="avatar"
                  max-height="350"
                  max-width="200"
                  :src="avatarShadow"
                ></v-img>
                <v-img
                  class="avatar"
                  max-height="350"
                  max-width="200"
                  :src="avatarBody"
                ></v-img>
                <v-img
                  class="avatar"
                  max-height="350"
                  max-width="200"
                  :style="backgroundColor"
                  :src="avatarBody"
                ></v-img>
                <v-img
                  class="avatar"
                  max-height="350"
                  max-width="200"
                  :src="avatarCheek"
                ></v-img>
                <v-img
                  class="avatar"
                  max-height="350"
                  max-width="200"
                  :src="avatarOutline"
                ></v-img>
                <v-img
                  class="avatar"
                  max-height="350"
                  max-width="200"
                  :src="avatarMouth"
                ></v-img>
                <v-img
                  class="avatar"
                  max-height="350"
                  max-width="200"
                  :src="avatarHair"
                ></v-img>
                <v-img
                  class="avatar"
                  max-height="350"
                  max-width="200"
                  :src="avatarEye"
                ></v-img>
              </div>
            </v-col>
            <v-col cols="12" sm="7" class="pt-5">
              <div class="d-flex justify-space-between align-center my-3" v-if="this.auth.User.type != 'adult'">
                <ArrowBtn
                  icon="ArrowButtonPre"
                  :callback="selectBodyIncrease"
                ></ArrowBtn>
                {{ $t("string.body") }}
                <ArrowBtn
                  icon="ArrowButtonNex"
                  :callback="selectBodyDecrease"
                ></ArrowBtn>
              </div>
              <!-- <div class="d-flex justify-space-between align-center my-3"v-if="this.auth.User.type != 'adult'">
                <ArrowBtn
                  icon="ArrowButtonPre"
                  :callback="selectMouthIncrease"
                ></ArrowBtn>
                {{ $t("string.mouth") }}
                <ArrowBtn
                  icon="ArrowButtonNex"
                  :callback="selectMouthDecrease"
                ></ArrowBtn>
              </div> -->
              <div class="d-flex justify-space-between align-center my-3">
                <ArrowBtn
                  icon="ArrowButtonPre"
                  :callback="selectColorIncrease"
                ></ArrowBtn>
                {{ $t("string.color") }}
                <ArrowBtn
                  icon="ArrowButtonNex"
                  :callback="selectColorDecrease"
                ></ArrowBtn>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="px-5">
          <v-btn
            color="green lighten-1"
            large
            @click="next"
            :loading="api.isLoading"
            block
            rounded
            class="text-h6"
            style="text-transform: capitalize"
          >
            {{ $t("action.next") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
let click_sfx = new Audio(require("@/assets/sounds/sfx_click_5.wav"));
export default {
  components: {
    CloseBtn: () =>
      import(
        /* webpackChunkName: "component-btn-close" */ "@/components/btns/CloseBtn"
      ),
    ApiErrorDialog: () =>
      import(
        /* webpackChunkName: "component-api-error-dialog" */ "@/components/ApiErrorDialog.vue"
      ),
    ArrowBtn: () =>
      import(
        /* webpackChunkName: "component-btn-arrow" */ "@/components/btns/ArrowBtn"
      ),
  },
  data: () => ({
    selectedKey: null,
    authData: {},
    mouth: [],
    body: [],
    outline: [],
    shadow: [],
    eye: [],
    hair: [],
    cheek: [],
    avatarBody: null,
    avatarOutline: null,
    avatarMouth: null,
    avatarShadow: null,
    avatarEye: null,
    avatarHair: null,
    avatarCheek: null,
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    selectedMouth: 0,
    selectedBody: 0,
    selectedAvatarBody: 0,
    color: [
      "F1F1F1",
      "FF7473",
      "FFBABB",
      "7396FF",
      "B0DBF1",
      "58C881",
      "B6D59A",
      "8550C5",
      "CAA1DD",
      "F3E92A",
      "594835",
      "A6A4A6",
    ],
    selectedColor: 0,
    avatar: {
      charKey: "mochi_v4",
      body: "",
      mouth: "",
      pColor: "F1F1F1",
      hat: "",
      left: "",
      right: "",
      back: "",
    },
    characters: [],
  }),
  computed: {
    ...mapState({
      auth: (state) => state.auth.data,
      settings: (state) => state.settings.data,
    }),
    backgroundColor() {
      return (
        "filter : opacity(0.5) drop-shadow(0 0 0 #" +
        this.color[this.selectedColor] +
        " )"
      );
    },
  },
  props: [
    //
  ],

  created() {
    this.api.method = "get";
    this.api.url =
      this.$api.servers.game +
      "/api/v1/" +
      this.$_getLocale() +
      "/library/skin/load";

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
      this.api.error = null;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.skin = this.$_.groupBy(resp, "layer");
      this.$store.commit("updateSkin", this.skin);
      if (this.auth.User.type == "child") {
        this.selectSkinForChild();
      } else if (this.auth.User.type == "adult") {
        this.selectSkinForAdult();
      }
    };
  },
  mounted() {
    if (this.auth.User.type == "child") {
        this.avatar.body = "rabbit";
        this.avatar.mouth = "rabbit";
      } else if (this.auth.User.type == "adult") {
        this.avatar.body = "owl";
        this.avatar.mouth = "owl";
      }
    this.$api.fetch(this.api);
  },
  methods: {
    selectBodyIncrease() {
      this.selectBody("increase");
      this.selectMouth("increase");
    },
    selectBodyDecrease() {
      this.selectBody("decrease");
      this.selectMouth("decrease");
    },
    // selectMouthIncrease() {
    //   this.selectMouth("increase");
    // },
    // selectMouthDecrease() {
    //   this.selectMouth("decrease");
    // },
    selectColorIncrease() {
      this.selectColor("increase");
    },
    selectColorDecrease() {
      this.selectColor("decrease");
    },
    selectSkinForAdult() {
      this.body = this.$_.filter(this.skin.body, ["character", "owl"]);
      this.outline = this.$_.filter(this.skin.outline, ["character", "owl"]);
      this.mouth = this.$_.filter(this.skin.mouth, ["character", "owl"]);
      this.shadow = this.skin.shadow;
      this.eye = this.skin.eye;
      this.hair = this.skin.hair;
      this.cheek = this.skin.cheek;

      this.avatarShadow = JSON.parse(this.shadow[0].images).F0.url;
      this.avatarEye = JSON.parse(this.eye[0].images).F0.url;
      this.avatarHair = JSON.parse(this.hair[0].images).F0.url;
      this.avatarCheek = JSON.parse(this.cheek[0].images).F0.url;
      this.avatarBody = JSON.parse(this.body[0].images).F0["url"];
      this.avatarOutline = JSON.parse(this.outline[0].images).F0["url"];
      this.avatarMouth = JSON.parse(this.mouth[0].images).F0["url"];
    },

    selectSkinForChild() {
      this.body = this.$_.filter(this.skin.body, function (o) {
        return !o.isAdult && o.character != "rat";
      });
      this.outline = this.$_.filter(this.skin.outline, function (o) {
        return !o.isAdult && o.character != "rat";
      });
      this.mouth = this.$_.filter(this.skin.mouth, function (o) {
        return !o.isAdult && o.character != "rat";
      });
      this.shadow = this.skin.shadow;
      this.eye = this.skin.eye;
      this.hair = this.skin.hair;
      this.cheek = this.skin.cheek;

      this.characters = this.$_.map(this.body, "character");

      var characters = this.characters;
      var body = this.body.filter(function (entry) {
        return entry.character == characters[0];
      });

      var outline = this.outline.filter(function (entry) {
        return entry.character == characters[0];
      });

      this.avatarShadow = JSON.parse(this.shadow[0].images).F0.url;
      this.avatarEye = JSON.parse(this.eye[0].images).F0.url;
      this.avatarHair = JSON.parse(this.hair[0].images).F0.url;
      this.avatarCheek = JSON.parse(this.cheek[0].images).F0.url;
      this.avatarBody = JSON.parse(body[0].images).F0["url"];
      this.avatarOutline = JSON.parse(outline[0].images).F0["url"];
      this.avatarMouth = JSON.parse(this.mouth[this.selectedMouth].images).F0[
        "url"
      ];
    },
    selectMouth(type) {
      if (this.mouth.length == this.selectedMouth + 1 && type == "increase") {
        this.selectedMouth = 0;
      } else if (this.selectedMouth == 0 && type == "decrease") {
        this.selectedMouth = this.mouth.length - 1;
      } else {
        if (type == "increase") {
          this.selectedMouth = this.selectedMouth + 1;
        } else {
          this.selectedMouth = this.selectedMouth - 1;
        }
      }
      this.avatarMouth = JSON.parse(this.mouth[this.selectedMouth].images).F0[
        "url"
      ];
      this.avatar.mouth = this.mouth[this.selectedMouth].character;
    },

    selectBody(type) {
      if (this.body.length == this.selectedBody + 1 && type == "increase") {
        this.selectedBody = 0;
      } else if (this.selectedBody == 0 && type == "decrease") {
        this.selectedBody = this.body.length - 1;
      } else {
        if (type == "increase") {
          this.selectedBody = this.selectedBody + 1;
        } else {
          this.selectedBody = this.selectedBody - 1;
        }
      }

      var characters = this.characters;
      var selectedBody = this.selectedBody;
      var body = this.body.filter(function (entry) {
        return entry.character == characters[selectedBody];
      });

      var outline = this.outline.filter(function (entry) {
        return entry.character == characters[selectedBody];
      });

      this.avatarBody = JSON.parse(body[0].images).F0["url"];
      this.avatarOutline = JSON.parse(outline[0].images).F0["url"];
      this.avatar.body = this.body[this.selectedBody].character;
    },

    selectColor(type) {
      if (this.color.length == this.selectedColor + 1 && type == "increase") {
        this.selectedColor = 0;
      } else if (this.selectedColor == 0 && type == "decrease") {
        this.selectedColor = this.color.length - 1;
      } else {
        if (type == "increase") {
          this.selectedColor = this.selectedColor + 1;
        } else {
          this.selectedColor = this.selectedColor - 1;
        }
      }
      this.avatar.pColor = this.color[this.selectedColor];
    },
    exit() {
      this.$router.push({
        name:
          this.auth.Group.type == "family"
            ? "PageAccessFamily"
            : "PageAccessClassroom",
      });
    },
    next() {
      this.authData = this.$_.cloneDeep(this.auth);
      this.authData["Player"].avatar = JSON.stringify(this.avatar);
      this.$store.commit("updateAuth", this.authData);
      click_sfx.play();
      click_sfx.volume = this.settings.audio.sfx * this.settings.audio.master;
      this.$router.push({
        name: "PageAccessOnboardingPassword",
      });
    },
  },
};
</script>
<style scoped>
.avatar {
  position: absolute;
}
.title {
  pointer-events: none;
  font-size: 1em !important;
}
</style>